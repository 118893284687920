import React from 'react';

const LoadingPage = ({ fadeOut }) => {
  const loadingStyles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      background: 'linear-gradient(180deg, #FFE6E6 0%, #E6FFE6 100%)',
      opacity: fadeOut ? 0 : 1,
      transition: 'opacity 0.5s ease-out',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1000,
    },
    flagContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '80px',
    },
    flag: {
      width: '60px',
      height: '60px',
      margin: '0 10px',
      animation: 'wave 1.5s infinite',
    },
    redFlag: {
      color: '#FF6B6B',
    },
    yellowFlag: {
      color: '#FFA500',
      animationDelay: '0.5s',
    },
    greenFlag: {
      color: '#4CAF50',
      animationDelay: '1s',
    },
    
    text: {
      fontSize: '16px',
      color: '#666',
      fontFamily: 'Arial, sans-serif',
    },
    '@keyframes wave': {
      '0%, 100%': {
        transform: 'rotate(0deg)',
      },
      '50%': {
        transform: 'rotate(20deg)',
      },
    },
  };

  return (
    <div style={loadingStyles.container}>
      <div style={loadingStyles.flagContainer}>
        <svg style={{...loadingStyles.flag, ...loadingStyles.redFlag}} viewBox="0 0 24 24">
          <path fill="currentColor" d="M14,6L14,4H5v17h2v-7h5.6l0.4,2h7V6H14z" />
        </svg>
        <svg style={{...loadingStyles.flag, ...loadingStyles.yellowFlag}} viewBox="0 0 24 24">
          <path fill="currentColor" d="M14,6L14,4H5v17h2v-7h5.6l0.4,2h7V6H14z" />
        </svg>
        <svg style={{...loadingStyles.flag, ...loadingStyles.greenFlag}} viewBox="0 0 24 24">
          <path fill="currentColor" d="M14,6L14,4H5v17h2v-7h5.6l0.4,2h7V6H14z" />
        </svg>
        
      </div>
      
      <div style={loadingStyles.text}>Spill the tea.. Let AI sift the shade.</div>
      <style>
        {`
          @keyframes wave {
            0%, 100% { transform: rotate(0deg); }
            50% { transform: rotate(20deg); }
          }
        `}
      </style>
    </div>
  );
};

export default LoadingPage;