import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import imageCompression from 'browser-image-compression';

const compressImage = async (file) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  };

  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.error("Error compressing image:", error);
    return file;
  }
};

const ImageUpload = ({ onImageUpload, initialImages = [] }) => {
  const [imagePreviews, setImagePreviews] = useState(initialImages);
  const [uploading, setUploading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setImagePreviews(initialImages);
    onImageUpload(initialImages);
  }, [initialImages, onImageUpload]);

  const uploadImage = useCallback(async (file) => {
    const formData = new FormData();
    const compressedFile = await compressImage(file);
    formData.append('file', compressedFile);
    formData.append('upload_preset', process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET);

    setUploading(true);

    try {
      const response = await axios.post('https://api.cloudinary.com/v1_1/duhce1uy4/image/upload', formData);
      const imageUrl = response.data.secure_url;
      setImagePreviews(prev => [...prev, imageUrl]);
      onImageUpload([...imagePreviews, imageUrl]);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setUploading(false);
    }
  }, [imagePreviews, onImageUpload]);

  const handleImageChange = useCallback(async (event) => {
    const files = event.target.files;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        await uploadImage(files[i]);
      }
    }
  }, [uploadImage]);

  const handlePaste = useCallback(async (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;
    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        const blob = item.getAsFile();
        await uploadImage(blob);
      }
    }
  }, [uploadImage]);

  const handleDeleteImage = useCallback((index) => {
    setImagePreviews(prev => {
      const newPreviews = [...prev];
      newPreviews.splice(index, 1);
      onImageUpload(newPreviews);
      return newPreviews;
    });
  }, [onImageUpload]);

  const handleOpenModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };
    
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    document.addEventListener('paste', handlePaste);
    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, [handlePaste]);

  return (
    <>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="contained-button-file"
        type="file"
        onChange={handleImageChange}
        disabled={uploading}
        ref={fileInputRef}
        multiple
      />
      <label htmlFor="contained-button-file" style={{
        position: 'absolute',
        bottom: '5px',
        right: '5px',
        zIndex: 2
      }}>
        <IconButton 
          component="span" 
          disabled={uploading} 
          size="small"
          sx={{
            backgroundColor: 'white',
          }}
        >
          <AttachFileIcon fontSize="20px" />
        </IconButton>
      </label>
      <div style={{
        position: 'absolute',
        bottom: '5px',
        left: '5px',
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
        zIndex: 2,
        
      }}>
        {imagePreviews.map((preview, index) => (
          <div key={index} style={{
            width: '40px',
            height: '40px',
            overflow: 'hidden',
            borderRadius: '4px',
            boxShadow: '0 0 5px rgba(0,0,0,0.2)',
            position: 'relative',
            flexShrink: 0,
            transition: 'transform 0.2s ease-in-out',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'scale(1.05)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = 'scale(1)';
          }}
          >
            <img 
              src={preview} 
              alt="" 
              style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '4px', cursor: 'pointer' }} 
              onClick={() => handleOpenModal(preview)}
            />
            <IconButton
              onClick={() => handleDeleteImage(index)}
              size="small"
              sx={{
                position: 'absolute',
                top: -5,
                right: -5,
                padding: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                color: '#000',
                width: '20px',
                height: '20px',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                },
              }}
            >
              <CloseIcon sx={{ fontSize: 14 }} />
            </IconButton>
          </div>
        ))}
      </div>
      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md">
        <img src={selectedImage} alt="" style={{ width: '100%', height: 'auto' }} />
      </Dialog>
    </>
  );
};

export default ImageUpload;