import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, TextField, Button } from '@mui/material';
import { storeFeedback } from './dataStorage';

const commonStyles = {
    fontFamily: 'Poppins, sans-serif',
    color: 'text.secondary',
};

const buttonStyles = {
    ...commonStyles,
    borderRadius: '100px',
    padding: '6px 23px',
    fontWeight: 'bold',
    fontSize: '1rem',
};

const FeedbackForm = ({ open, onClose }) => {
    const [feedback, setFeedback] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async () => {
        if (feedback.trim() === '') {
            alert('Please enter your feedback before submitting.');
            return;
        }

        setIsSubmitting(true);
        try {
            await storeFeedback(feedback);
            setFeedback('');
            onClose();
            alert('Thank you for your feedback!');
        } catch (error) {
            console.error('Error submitting feedback:', error);
            alert('There was an error submitting your feedback. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Provide Feedback</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="feedback"
                    label="Your feedback"
                    type="text"
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                />
            </DialogContent>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
            <Button
                    onClick={handleSubmit}
                    sx={{
                        ...buttonStyles,
                        backgroundColor: 'text.primary',
                        color: 'white',
                        '&:hover': { backgroundColor: 'text.secondary' },
                        mr: 1
                    }}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                </Button>
                <Button
                    onClick={onClose}
                    sx={{
                        ...buttonStyles,
                        backgroundColor: '#E5E7EB',
                        color: 'text.primary',
                        '&:hover': { backgroundColor: '#D1D5DB' },
                        mr: 1,
                    }}
                    disabled={isSubmitting}
                >
                    Cancel
                </Button>
            </Box>
        </Dialog>
    );
};

export default FeedbackForm;